<template>
  <Dialog v-model:visible="visible" :style="{width: '450px'}" header="Step details" :modal="true" class="grid p-fluid">
    <div class="col-12 md:col-12">
      <div class="grid">
        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
          <label for="order">Order</label>
          <InputText id="order" v-model.trim="entityData.order" required="true" autofocus :class="{'p-invalid': submitted && !entityData.order}" />
          <small class="p-invalid" v-if="submitted && !entityData.order">Order is required.</small>
        </div>
      </div>

      <div class="grid">
        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
          <label for="question_id" class="mb-3">Question</label>
          <Dropdown id="question_id" v-model="entityData.question_id" :options="questions" option-label="title" placeholder="Select a question" option-value="id">
          </Dropdown>
        </div>
      </div>
    </div>

    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="$emit('hideDialog')"/>
      <Button label="Save" icon="pi pi-check" class="p-button-text" @click="save" />
    </template>
  </Dialog>
</template>

<script>

import StepQuestionService from "@/service/StepQuestionService";
import QuestionService from "@/service/QuestionService";

export default {
  name: "StepQuestionForm",
  emits: ['hideDialog','saved'],
  props: {
    entity: Object,
    dialog: Boolean,
    stepId: String,
  },
  entityService: null,
  questionService: null,

  computed: {
    visible: {
      get() { return this.dialog },
      set(visible) {this.$emit('hideDialog', visible)}
    },
    entityData: {
      get() { return this.entity },
    }
  },

  created() {
    this.entityService = new StepQuestionService();
    this.questionService = new QuestionService();
  },

  mounted() {
    this.questionService.fetchAll().then(data => this.questions = data.data);
  },

  data() {
    return {
      submitted: false,
      inputTypes: [],
    }
  },

  methods: {
    save() {
      this.submitted = true;

      if (this.entity.id) {
        this.entityService.update(this.entityData).then(response => {
          this.$toast.add({severity:'success', summary: 'Success', detail: 'Step question updated', life: 3000});
          this.$emit('saved', response);
        });
      } else {
        this.entityData.step_id = this.stepId;
        this.entityService.create(this.entityData).then(response => {
          this.$toast.add({severity:'success', summary: 'Success', detail: 'Step question created', life: 3000});
          this.$emit('saved', response);
        });
      }
    },
  }
}
</script>

<style scoped>

</style>
