import http from "@/service/HttpService";
import axios from "axios";

export default class StepQuestionService {

    fetchAll(stepId) {
        return http.get(`step-question?filter[step_id]=${stepId}&include=question&per_page=100&sort=order`).then(d => d.data);
    }

    create(entity) {
        return http.post(`step-question`, entity).then(response => response.data);
    }

    update(entity) {
        return http.put(`step-question/${entity.id}`, entity).then(response => response.data);
    }

    delete(entity) {
        return http.delete(`step-question/${entity.id}`).then(response => response.data);
    }

    bulkReorder(entities) {
        const requests = [];
        for(const entity of entities) {
            requests.push(http.put(`step-question/${entity.id}`, {id: entity.id, order: entity.order}));
        }

        return axios.all(requests).then()
    }

    bulkDelete(entities) {
        const requests = [];
        for(const entity of entities) {
            requests.push(http.delete(`step-question/${entity.id}`));
        }

        return axios.all(requests).then()
    }
}
